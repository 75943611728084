import React from "react";

const subHeaderStyle = {
  color: "#111",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  textAlign: "left",
};

class SubHeader extends React.Component {
  render() {
    const { text } = this.props;
    return <p style={subHeaderStyle}>{text}</p>;
  }
}

export default SubHeader;
