import React from "react";
import MainHeader from "../main-header/main-header";
import Text from "../text/text";
import SubHeader from "../sub-header/sub-header";
import Footer from "../footer/footer";
import NavBar from "../navbar/navbar";

class PrivacyPolicy extends React.Component {
  render() {
    const listStyle = {
      color: "#787878",
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      textAlign: "left",
      listStyle: "disc",
      paddingLeft: "24px",
      display: "flex",
      flexDirection: "column",
      gap: "4px,",
    };
    return (
      <>
        <div className="sticky top-0 z-40 bg-white shadow-sm">
          <NavBar />
        </div>
        <div className="py-16 px-20 flex flex-col gap-[12px]">
          <MainHeader text={"Privacy policy"} />
          <Text text={"Last updated: January 15, 2024"} />
          <SubHeader text={"Information We Collect"} />

          <Text
            text={
              "Thank you for visiting our website and purchasing our Coconutshell Activated Carbon products. We respect your privacy and want you to understand how we collect, use, and share data when you visit our site or make a purchase. This Privacy Policy explains our practices."
            }
          />

          <Text
            text={
              "Information collected automatically includes your IP address, device type, referral URLs, browser type, operating system, date/time stamps, and details about the pages you visit. We use cookies and navigational data to operate and improve our site."
            }
          />

          <SubHeader text={"We use the information we collect to:"} />

          <ul style={listStyle}>
            <li>Process, fulfill and follow up on your orders</li>
            <li>
              Improve our products, site experience, and customer service{" "}
            </li>
            <li>Respond to submitted inquiries and requests </li>
            <li> Administer contests or promotions</li>
          </ul>

          <SubHeader text={"We use the information we collect to:"} />

          <Text
            text={
              "We may also use data for marketing purposes to reach out about new products or promotions. You can control marketing messages through your account and communication preferences."
            }
          />

          <SubHeader text={"When We Disclose Information"} />
          <Text
            text={
              "We do not sell or rent your personal data to third parties. We only disclose data to service providers and partners assisting us with operations or services related to your purchase."
            }
          />

          <SubHeader text={"Your Rights and Choices"} />
          <Text
            text={
              "You may opt-out of email marketing communications by following opt-out links in emails or by adjusting your communication preferences in your account. You may request deletion of personal data collected through our website by contacting us. You have the right to request details about the information we store about you."
            }
          />

          <SubHeader text={"Security"} />
          <Text
            text={
              "We take reasonable security measures to protect against loss, theft, unauthorized access or disclosure of your personal information. However, no internet transmission is ever fully secure, so we cannot guarantee the security of information transmitted to or from our site."
            }
          />

          <SubHeader text={"Updates to Privacy Policy"} />
          <Text
            text={
              "We may make changes to the Privacy Policy periodically. The updated version will be effective immediately once posted on our site. If we make significant changes, we may provide additional notice through our website or email."
            }
          />

          <SubHeader text={"Contact Us"} />
          <Text
            text={
              "If you have any questions about our privacy practices or this policy, please contact us at: chosenexim@gmail.com"
            }
          />
        </div>
        <Footer />
      </>
    );
  }
}

export default PrivacyPolicy;
