import React from "react";

class MainHeader extends React.Component {
  render() {
    const { text } = this.props;
    const mystyle = {
      color: "#111",
      textAlign: "left",
      fontFamily: "Inter",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    };

    return <h2 style={mystyle}>{text}</h2>;
  }
}
export default MainHeader;
