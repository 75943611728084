import ContentImageOne from "./assets/sec_one.jpeg";
import ContentImageTwo from "./assets/sec_two.jpeg";
import HomeImage from "./assets/hero_img.jpeg";
import ImageOne from "./assets/about_one.jpeg";
import ImageTwo from "./assets/about_two.jpeg";
import ImageThree from "./assets/about_three.jpeg";
import WhyImage from "./assets/sec_one.jpeg";
import CarbonUseImage from "./assets/carbon-use.jpeg";

import Gold from "./assets/gold_recovery.jpeg";
import AirPage from "./assets/air.jpeg";
import WaterPhase from "./assets/water_phase.png";

import CollageOne from "./assets/collage/collage_1.jpeg";
import CollageTwo from "./assets/collage/collage_2.jpeg";
import CollageThree from "./assets/collage/collage_3.jpeg";
import CollageFour from "./assets/collage/collage_4.jpeg";
import CollageFive from "./assets/collage/collage_5.jpeg";
import CollageSix from "./assets/collage/collage_6.jpeg";
import CollageSeven from "./assets/collage/collage_7.jpeg";
import CollageEight from "./assets/collage/collage_8.jpeg";
import CollageNine from "./assets/collage/collage_9.jpeg";
import CollageTen from "./assets/collage/collage_10.jpeg";
import CollageEleven from "./assets/collage/collage_11.jpeg";
import CollageTwelve from "./assets/collage/collage_12.jpeg";
import CollageThirteen from "./assets/collage/collage_13.jpeg";
import CollageFourteen from "./assets/collage/collage_14.jpeg";
import CollageFifteen from "./assets/collage/collage_15.jpeg";
import CollageSixteen from "./assets/collage/collage_16.jpeg";

export const collageImageOne = CollageOne;
export const collageImageTwo = CollageTwo;
export const collageImageThree = CollageThree;
export const collageImageFour = CollageFour;
export const collageImageFive = CollageFive;
export const collageImageSix = CollageSix;
export const collageImageSeven = CollageSeven;
export const collageImageEight = CollageEight;
export const collageImageNine = CollageNine;
export const collageImageTen = CollageTen;
export const collageImageEleven = CollageEleven;
export const collageImageTwelve = CollageTwelve;
export const collageImageThirteen = CollageThirteen;
export const collageImageFourteen = CollageFourteen;
export const collageImageFifteen = CollageFifteen;
export const collageImageSixteen = CollageSixteen;

export const collageImageArray = [
  collageImageOne,
  collageImageTwo,
  collageImageThree,
  collageImageFour,
  collageImageFive,
  collageImageSix,
  collageImageSeven,
  collageImageEight,
  collageImageNine,
  collageImageTen,
  collageImageEleven,
  collageImageTwelve,
  collageImageThirteen,
  collageImageFourteen,
  collageImageFifteen,
  collageImageSixteen,
];

export const heroImageCarbon = HomeImage;
export const aboutImageOne = ImageOne;
export const aboutImageTwo = ImageTwo;
export const aboutImageThree = ImageThree;
export const WhyUsImage = WhyImage;
export const CarbonUse = CarbonUseImage;

export const aboutText =
  "The key audiences are industries like water treatment, air purification, gas purification, automotive, and medicine which utilize activated carbon for its adsorption and purification capabilities.";
export const aboutHeader = "Who is our audience?";

export const contentHeaderCarbonPage =
  "Where are all activated carbon can be used";
export const contentSubHeaderCarbonPage =
  "At Carbonix, our land is giving us a great Quality of coconut-shell that empowers people and enriches their lives all around the Globe.";
export const contentTextCarbonPage =
  "At Carbonix, our land is giving us a great Quality of coconut-shell that empowers people and enriches their lives all around the Globe. Activated carbon, also known as activated charcoal, is a versatile material with a wide range of applications due to its high surface area and adsorption properties. Here are some common applications of activated carbon:";
export const contentPointsCarbonPage = [
  {
    subHeader: "Water Purification:",
    point: [
      "Water Treatment: Activated carbon is widely used in water treatment processes to remove impurities, organic contaminants, and pollutants. It can adsorb various chemicals, including chlorine, pesticides, and industrial by-products.",
      "Water Filtration: Activated carbon filters are commonly used in home water filtration systems to improve taste and remove odor from tap water.",
    ],
  },
  {
    subHeader: "Air Purification:",
    point: [
      "Air Filtration:  Activated carbon is used in air filters to adsorb volatile organic compounds (VOCs), odors, and other pollutants. It is often part of air purifiers and HVAC systems.",
      "Gas Masks and Respirators:  Activated carbon is a key component in gas masks and respirators, providing protection against harmful gases and airborne toxins.",
    ],
  },
  {
    subHeader: "Food and Beverage Processing:",
    point: [
      "Food and Beverage Industry: Activated carbon is employed in the food and beverage industry for decolorization, deodorization, and purification of liquids. It is used in the production of sugar, edible oils, and alcoholic beverages.",
    ],
  },
  {
    subHeader: "Industrial Applications:",
    point: [
      "Industrial Processes: It is used in industrial applications, including gas purification, solvent recovery, and the removal of impurities from process streams.",
    ],
  },
  {
    subHeader: "Electronics Industry:",
    point: [
      "Semiconductor Manufacturing: Activated carbon is used in the purification of process gases and air in semiconductor manufacturing to maintain high purity levels.",
    ],
  },
  {
    subHeader: "Cosmetics and Personal Care:",
    point: [
      "Skincare Products: Activated carbon is included in some skincare products, such as masks and cleansers, for its ability to adsorb impurities and toxins from the skin.",
    ],
  },
  {
    subHeader: "Flue Gas Treatment:",
    point: [
      "Power Plants: Activated carbon is used in flue gas treatment to capture mercury emissions and other pollutants.",
    ],
  },
  {
    subHeader: "Oil and Gas Industry:",
    point: [
      "Oil Refining: Activated carbon is used in the purification of oils and fats in the refining process.",
    ],
  },
  {
    subHeader: "Battery Manufacturing:",
    point: [
      "Battery Electrodes: Activated carbon can be used as an electrode material in certain types of batteries,such as double-layer capacitors.",
    ],
  },
  {
    subHeader: "Metal Recovery:",
    point: [
      "Metal Extraction: Activated carbon is utilized in the extraction of metals from solutions, such as the recovery of precious metals from mining solutions.",
    ],
  },
  {
    subHeader: "Electrochemical Capacitors:",
    point: [
      "Supercapacitors and Energy Storage Devices: Activated carbon is utilized as an electrode material in electrochemical capacitors for energy storage applications.",
    ],
  },
  {
    subHeader: "Chemical Catalysis:",
    point: [
      "Catalyst Support: Activated carbon can serve as a support material for catalysts in various chemical reactions due to its high surface area and porous structure.",
    ],
  },
  {
    subHeader: "Sound Absorption:",
    point: [
      "Activated carbon, when processed into foams or other structures, can be used for sound absorption in applications like noise barriers and acoustic panels.",
    ],
  },
  {
    subHeader: "Flavor and Fragrance Industry:",
    point: [
      "Deodorization and Purification: Activated carbon is used in the flavor and fragrance industry to remove impurities and unwanted odors from products.",
    ],
  },
  {
    subHeader: "Chemical Processing:",
    point: [
      "Chemical Industry: Activated carbon plays a role in various chemical processes, such as catalyst support,purification of chemicals, and removal of impurities",
    ],
  },
  {
    subHeader: "Dental Products:",
    point: [
      "Toothpaste and Oral Care Products: Activated carbon is sometimes included in toothpaste and oralcare products for its potential teeth-whitening properties and ability to adsorb toxins.",
    ],
  },
  {
    subHeader: "Animal Health:",
    point: [
      "Livestock Feed Additives: Activated carbon may be added to livestock feed to adsorb toxins and improve digestive health.",
    ],
  },
  {
    subHeader: "Automotive Applications:",
    point: [
      "Cabin Air Filters: Activated carbon is used in cabin air filters in vehicles to remove odors and pollutants from the air entering the passenger compartment.",
    ],
  },
  {
    subHeader: "Environmental Remediation:",
    point: [
      "Remediation of Contaminated Sites: Activated carbon can be applied to adsorb and remove contaminants from soil and groundwater, aiding in the remediation of polluted sites.",
    ],
  },
  {
    subHeader: "Gold Recovery:",
    point: [
      "Gold Mining: Activated carbon is used in the extraction of gold from cyanide solutions. It adsorbs gold effectively, allowing for the separation and recovery of the precious metal.",
    ],
  },
  {
    subHeader: "Energy Storage:",
    point: [
      "Supercapacitors: Activated carbon is used as a material for the electrodes in supercapacitors, providing high surface area and electrical conductivity.",
    ],
  },
  {
    subHeader: "Agriculture:",
    point: [
      "Soil Amendments: Activated carbon can be added to soil to improve its structure, water retention, and nutrient availability. It can also help in the remediation of contaminated soils.",
    ],
  },
  {
    subHeader: "Hydroponics:",
    point: [
      "Water Filtration in Hydroponic Systems: Activated carbon filters are used in hydroponic systems to remove impurities and improve water quality for plant growth.",
    ],
  },
  {
    subHeader: "Renewable Energy:",
    point: [
      "Biogas Purification: Activated carbon is employed in the purification of biogas to remove impurities like hydrogen sulfide, making it suitable for use as a renewable energy source.",
    ],
  },
  {
    subHeader: "Nuclear Industry:",
    point: [
      "Nuclear Power Plants: Activated carbon can be used for the removal of radioactive substances from air and water in nuclear facilities.",
    ],
  },
  {
    subHeader: "Textile Industry:",
    point: [
      "Color Removal: Activated carbon is used in the textile industry for the removal of color and impurities from dyeing wastewater",
    ],
  },
  {
    subHeader: "Hygiene Products:",
    point: [
      "Diapers and Sanitary Pads: Activated carbon is sometimes incorporated into hygiene products for its odor-absorbing properties.",
    ],
  },
  {
    subHeader: "Acoustic Materials:",
    point: [
      "Activated carbon, when processed into foams or other structures, can be used for sound absorption in applications like noise barriers and acoustic panels.",
    ],
  },
  {
    subHeader: "Tobacco Filtration:",
    point: [
      "Cigarette Filters: Activated carbon is sometimes used in cigarette filters to adsorb some of the smoke constituents.",
    ],
  },
  {
    subHeader: "Gas Storage:",
    point: [
      "Natural Gas Storage: Activated carbon is used for the storage of natural gas, helping to adsorb and store gases at high pressures.",
    ],
  },
  {
    subHeader: "Petrochemical Industry:",
    point: [
      "Solvent Recovery: Activated carbon is used in the recovery of solvents in the petrochemical industry, helping to purify and recycle solvents.",
    ],
  },
  {
    subHeader: "Pyrotechnics:",
    point: [
      "Smoke Production: Activated carbon can be used in certain pyrotechnic applications to produce smoke screens or colored smoke.",
    ],
  },
  {
    subHeader: "Desalination:",
    point: [
      "Brackish Water Desalination: Activated carbon is used in brackish water desalination processes to remove organic compounds and impurities, improving the efficiency of the desalination process.",
    ],
  },

  {
    subHeader: "Paper Industry:",
    point: [
      "Pulp and Paper Processing: Activated carbon is used in the treatment of process water and effluents in the pulp and paper industry.",
    ],
  },
  {
    subHeader: "Medical and Pharmaceutical:",
    point: [
      "Medical Applications: Activated carbon is used in medical settings to treat certain types of poisoning or overdose. It can adsorb toxins in the gastrointestinal tract.",
      "Pharmaceuticals: Activated carbon is used in the pharmaceutical industry for purification and removalof impurities from various chemical substances.",
    ],
  },
];

export const contentHeaderWhyPage = "Why we are better than others";
export const contentTextWhyPage =
  "We Produce the hardest and long-lasting Activated carbon most effective activated carbon using specialized steam activation.";
export const contentSubHeaderWhyPage =
  "Here are the 14 Reasons Why We Are Better Than Others";
export const contentPointsWhyPage = [
  {
    header: "1. Evaluate Quality Specifications",
    point: [
      "When Exporting coconut shell activated carbon, We review the quality specifications like iodine number, mesh size, ash content,CTC etc. to ensure it meets your application requirements.",
    ],
  },
  {
    header: "2. We Provide Consistent Quality",
    point: [
      "Choose us for providing consistent and reliable activated carbon that complies with relevant quality standards.",
    ],
  },
  {
    header: "3. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "4. You Can Audit Supplier Production Facilities Anytime",
    point: [
      "You can Inspect Our facilities and quality control measures to ensure we adhere to proper industry standards.",
    ],
  },
  {
    header: "5. We Can Provide Relevant Certifications",
    point: [
      "We have the Capability to get the required certifications for your country ",
    ],
  },
  {
    header: "6. We have a Relaible Shipping Logistics Partner",
    point: [
      "We have a Partnership with trustable shipping Agent Who can take care of ,customs processes,safe container handling, local logistics to ensure timely and efficient delivery.",
    ],
  },
  {
    header: "7. Thorough Inspection In Packaging",
    point: [
      "Every packaging meets standards to prevent Moisture or damage during shipping and handling.",
    ],
  },
  {
    header: "8. Free Product Samples for Testing",
    point: [
      "Order samples to test the product quality before committing to a large order.",
    ],
  },
  {
    header: "9. We will give you Pricing Update",
    point: [
      "We will let you know the  market fluctuations in pricing and supply/demand to make informed sourcing decisions.",
    ],
  },
  {
    header: "10. We check Quality Inspections Before loading",
    point: [
      "We inspect to verify product condition and compliance before loading.",
    ],
  },
  {
    header: "11. Evaluate Application Compatibility",
    point: [
      "We can send you samples to ensure the activated carbon will be compatible with your intended application or you can send us samples we will test it here and give you the suitable product",
    ],
  },
  {
    header: "12.You Can Expect Clear Communication",
    point: [
      "We will communicate clearly with you 24/7 to address any questions and build a strong relationship.",
    ],
  },
  {
    header: "13. We Manage Inventory for Consistent Supply",
    point: [
      "Forecast demand and manage inventory to prevent activated carbon stockouts.",
    ],
  },
  {
    header: "14. Regular Updates Informed on Regulatory Changes",
    point: [
      "We Keep you updated on any evolving import/export regulations affecting the activated carbon supply chain.",
    ],
  },
];

export const contentImgOne = ContentImageOne;
export const contentTextOne =
  "We Produce the hardest and long-lasting Activated carbon most effective activated carbon using specialized steam activation.";
export const contentHeaderOne = "Why we are better than others";
export const contentSubHeaderOne =
  "Here are the 14 Reasons Why We Are Better Than Others";

export const contentPointsOne = [
  {
    header: "1. Evaluate Quality Specifications",
    point: [
      "When Exporting coconut shell activated carbon, We review the quality specifications like iodine number, mesh size, ash content,CTC etc. to ensure it meets your application requirements.",
    ],
  },
  {
    header: "2. We Provide Consistent Quality",
    point: [
      "Choose us for providing consistent and reliable activated carbon that complies with relevant quality standards.",
    ],
  },
  {
    header: "3. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
];

export const contentImgTwo = ContentImageTwo;
export const contentTextTwo =
  "At Carbonix, our land is giving us a great Quality of coconut-shell that empowers people and enriches their lives all around the Globe. Activated carbon, also known as activated charcoal, is a versatile material with a wide range of applications due to its high surface area and adsorption properties. Here are some common applications of activated carbon:";
export const contentHeaderTwo = "Where are all activated carbon can be used";

export const contentPointsTwo = [
  {
    subHeader: "Water Purification:",
    point: [
      "Water Treatment: Activated carbon is widely used in water treatment processes to remove impurities, organic contaminants, and pollutants. It can adsorb various chemicals, including chlorine, pesticides, and industrial by-products.",
      "Water Filtration: Activated carbon filters are commonly used in home water filtration systems to improve taste and remove odor from tap water.",
    ],
  },
  {
    subHeader: "Air Purification:",
    point: [
      "Air Filtration:  Activated carbon is used in air filters to adsorb volatile organic compounds (VOCs), odors, and other pollutants. It is often part of air purifiers and HVAC systems.",
      "Gas Masks and Respirators:  Activated carbon is a key component in gas masks and respirators, providing protection against harmful gases and airborne toxins.",
    ],
  },
];

export const contentGRPageImage = Gold;
export const contentHeaderGRPage = "Gold recovery";
export const contentSubHeaderGRPage =
  "Activated carbon plays an important role in recovering gold from ore in modern gold mining operations. Here are some of the key ways it is used:";
export const contentTextGRPage =
  "Gold's rarity, beauty, symbolism of wealth, monetary value, investment value, and durability and resistance to corrosion make it a prized metal that has been used as currency, jewelry, and a symbol of status throughout human history. Using activated carbon to capture gold. It was developed gradually over the 19th and 20th centuries through the work of multiple researchers.Activated carbon's incredibly porous structure and massive surface area allows it to adsorb and bind to gold particles, extracting them from solutions. This is due to intermolecular forces between the gold and the carbon surface. Activated carbon gold recovery evolved gradually over many decades through crucial practical research by chemists, metallurgists, and engineers at institutions like the U.S. Bureau of Mines. Their work identified the mechanisms and applications that make this technique so useful and widespread today.";
export const contentPointsGRPage = [
  {
    header: "Carbon-in-pulp",
    point: [
      "Finely ground activated carbon is mixed directly with crushed ore pulp in tanks. The carbon bonds with dissolved gold in the solution and is later separated via screening. This CIP method efficiently recovers gold.",
    ],
  },
  {
    header: "Carbon-in-leach",
    point: [
      "Similar to CIP, activated carbon is added to ore that has already been leached. The gold adsorbs onto the carbon active sites from the leach solution.",
    ],
  },
  {
    header: "Carbon stripping",
    point: [
      "After adsorption, the gold-laden carbon is treated with a hot caustic cyanide solution to strip the gold off the carbon into the solution. Electrowinning or zinc precipitation can then recover the gold.",
    ],
  },
  {
    header: "Carbon regeneration",
    point: [
      "Once stripped, activated carbon can be thermally and chemically treated to remove impurities and regenerate it for reuse in future adsorption cycles to recover more gold.",
    ],
  },
  {
    header: "Selective gold recovery",
    point: [
      "Activated carbon preferentially adsorbs gold over other materials like silvers and base metals, allowing selective gold recovery from complex ore.",
    ],
  },
];

export const contentAirPageImg = AirPage;
export const contentHeaderAirPage = "Air Phase";
export const contentSubHeaderAirPage =
  "Here are some of the main applications of coconut shell activated carbon granular in air phase applications:";
export const contentTextAirPage =
  "Air quality is vital for supporting life on Earth. One way to help improve air quality is through the use of coconut shell activated carbon. Activated carbon is a highly porous material that can adsorb and remove contaminants and pollutants from air. It is commonly used in gas purification applications and air filtration systems. Coconut shells are a useful source of activated carbon due to their natural porous structure and high carbon content. Coconut shell activated carbon is made by heating coconut shells to very high temperatures in the absence of oxygen, a process which removes moisture and volatile compounds. The resulting material has an incredibly high surface area and pores that provide many sites to capture airborne pollutants. When used in filtration systems, coconut shell activated carbon can effectively remove harmful substances like volatile organic compounds, odors, and smoke from the air. By adsorbing pollutants before they are inhaled, coconut shell activated carbon helps clean and purify ambient air. This leads to improved air quality, which is essential for human and environmental health. Contact us for More";
export const contentPointsAirPage = [
  {
    header: "Air purification/filtration",
    point: [
      "Coconut shell carbon is commonly used in air purifiers, HVAC systems, air handlers, gas masks etc. to remove odors, VOCs, toxic gases like SO2, and other impurities from air. The high porosity and surface area allows adsorption of a wide range of air contaminants.",
    ],
  },
  {
    header: "Respirator cartridges",
    point: [
      "Granular coconut shell carbon is a component of cartridges used in respirators and gas masks to filter out hazardous gases and vapors. It adsorbs gases like organic vapors, SO2, H2S etc.",
    ],
  },
  {
    header: "Solvent recovery",
    point: [
      "Activated carbon adsorption systems containing coconut shell carbon are used to recover solvents and VOCs from air streams in industries like painting, printing, dry cleaning etc. This prevents solvent release into the atmosphere.",
    ],
  },
  {
    header: "Indoor air quality control",
    point: [
      "Coconut shell carbon filters help maintain indoor air quality by removing formaldehyde, VOCs, and other hazardous gases released from furniture, carpets, cleaning agents etc. It improves air quality in offices, homes and closed environments.",
    ],
  },
  {
    header: "Air prefilters",
    point: [
      "Coconut shell carbon granules are used as prefiltration media in air handling systems of pharmaceutical, electronic and healthcare facilities to protect HEPA filters and remove airborne particulates.",
    ],
  },
  {
    header: "Air sampling in ecology",
    point: [
      "Coconut shell carbon traps are used to sample VOCs and aromatic compounds in forests and other natural ecosystems for ecological studies.",
    ],
  },
  {
    header: "Aircraft air quality",
    point: [
      "Coconut shell granular activated carbon is used in aircraft air filter systems and cabin air handlers to control air quality and remove odors, gases and VOCs in the aircraft cabin and cockpit.",
    ],
  },
  {
    header: "Fume hoods",
    point: [
      "Coconut shell carbon is used in filters for fume hoods in laboratories, chemical plants etc. to adsorb toxic fumes and gases released from chemicals/reactions. Prevents exposure for workers.",
    ],
  },
  {
    header: "Museum preservation",
    point: [
      "Coconut shell carbon is used in museum display cases and storage facilities to adsorb pollutants, acids, VOCs that can damage delicate artifacts and improve indoor air quality.",
    ],
  },
  {
    header: "Air separation",
    point: [
      "Coconut shell activated carbon is used as an adsorbent for air prepurification in industrial air separation plants to remove trace impurities like VOCs, aromatics and water vapor from air before cryogenic distillation.",
    ],
  },
  {
    header: "HVAC deodorization",
    point: [
      "Coconut shell carbon is impregnated with nutrients and used as deodorization filters in HVAC systems of commercial buildings to adsorb odors, VOCs and provide fresh indoor air.",
    ],
  },
  {
    header: "Sewage treatment",
    point: [
      "Coconut shell carbon is used to adsorb odorous gases like hydrogen sulfide, mercaptans, amines present in the exhaust air of sewage treatment facilities.",
    ],
  },
  {
    header: "Air sampling",
    point: [
      "Coconut shell carbon is used to adsorb and concentrate airborne chemicals and pollutants for analysis. This allows detection of contaminants at very low concentrations.",
    ],
  },
  {
    header: "Odor control",
    point: [
      "Coconut shell carbon is placed in odor control systems and air handlers to adsorb and remove odors and VOCs from air streams. Common applications are in wastewater treatment facilities, industrial sites, commercial kitchens etc.",
    ],
  },
  {
    header: "Automotive cabin air filters",
    point: [
      "Granular coconut carbon is incorporated into cabin air filters in vehicles and automobiles to purify intake air and remove fumes, odors and other gaseous pollutants.",
    ],
  },
  {
    header: "Mercury removal",
    point: [
      "Coconut shell carbon is used to adsorb elemental mercury from flue gases in coal-fired power plants, waste incinerators and other industries. This prevents mercury emission into the atmosphere.",
    ],
  },
  {
    header: "Smoke filters",
    point: [
      "Cigarette smoke filters contain granulated coconut shell carbon to adsorb toxic gases like hydrogen cyanide, nitrogen oxides, and volatile aldehydes present in cigarette smoke.",
    ],
  },
  {
    header: "Military gas masks",
    point: [
      "Activated carbon from coconut shells adsorbs toxic gases like phosgene, mustard gas and nerve agents for protection of military personnel. It is a critical component of military-grade gas masks and protective equipment.",
    ],
  },
  {
    header: "Dry cleaning",
    point: [
      "Activated carbon filters used in dry cleaning machines to adsorb and recover dry cleaning solvents like perchloroethylene contain coconut shell carbon to purify exhaust air.",
    ],
  },
  {
    header: "Breathing apparatus",
    point: [
      "Granulated coconut shell activated carbon is a component of breathing apparatus used by firefighters and emergency responders to filter air and adsorb toxic gases.",
    ],
  },
  {
    header: "Electronic manufacturing",
    point: [
      "Air filtration systems in cleanrooms and electronic manufacturing facilities utilize coconut shell carbon to remove airborne molecular contaminants like VOCs that can damage sensitive electronics.",
    ],
  },
  {
    header: "Electronic manufacturing",
    point: [
      "Air filtration systems in cleanrooms and electronic manufacturing facilities utilize coconut shell carbon to remove airborne molecular contaminants like VOCs that can damage sensitive electronics.",
    ],
  },
  {
    header: "Nuclear facilities",
    point: [
      "Activated carbon filters with coconut shell carbon are used to maintain air quality by removing radon, iodine and other radioactive particles in nuclear power plants, fuel reprocessing plants etc.",
    ],
  },
  {
    header: "Livestock farming",
    point: [
      "Activated carbon filters containing coconut shell carbon adsorb odors like ammonia and hydrogen sulfide from ventilation air in poultry and livestock rearing facilities.",
    ],
  },
  {
    header: "Landfill gas extraction",
    point: [
      "Landfill gas extraction systems utilize coconut shell carbon filters to remove contaminants and odorous compounds like mercaptans before combustion or energy recovery.",
    ],
  },
];

export const contentWaterPhaseImg = WaterPhase;

export const contentHeaderWaterPhase = "Water Phase";

export const contentTextWaterPhase =
  "Water is essential for humans and all living things. However, we often use and pollute water excessively. It is our responsibility to conserve and protect our water sources. One way we can help is by using coconut shell activated carbon which can remove impurities from water. It is also used in manufacturing processes for beverages like liquor, drinking water, and juices. By using coconut shell activated carbon, we can clean wastewater before returning it to the environment. Implementing technologies like this will allow us to use water more sustainably. With care and innovation, we can ensure clean water for generations to come. Mail our team to know more about this product. Coconut shell activated carbon has become an extremely useful adsorbent material for a wide variety of water treatment applications. Its highly porous structure and large surface area allow it to effectively remove dissolved organic contaminants, colors, odors, and even certain inorganic compounds from water streams. The granular form of coconut shell carbon can be used as a filter media in various processes across many industrial settings.";

export const contentSubHeaderWaterPhase =
  "Some key water phase applications where coconut shell activated carbon demonstrates great utility are";

export const contentPointsWaterPhase = [
  {
    header: "Evaluate Quality Specifications",
    point: [
      "Water purification - Coconut shell carbon is effective at removing chlorine, odors, tastes and organic contaminants like pesticides from drinking water. It is commonly used in water filters and purification systems.",
    ],
  },
  {
    header: "Groundwater remediation",
    point: [
      "Activated carbon adsorption is used to remove volatile organic compounds (VOCs), pesticides, and other hazardous pollutants from contaminated groundwater at remediation sites.",
    ],
  },
  {
    header: "Swimming pool water treatment",
    point: [
      "It helps eliminate unwanted organic matter, improves clarity and adsorbs chloramines and chlorine from pool water.",
    ],
  },
  {
    header: "Food and beverage processing",
    point: [
      "Coconut shell carbon is used to decolorize and remove contaminants from liquids in processes like sugar refining, oil purification, and beverage clarification.",
    ],
  },
  {
    header: "Landfill leachate treatment",
    point: [
      "Leachate contains high COD and ammonia levels along with organic toxins. Granular carbon filters can remove the contaminants.",
    ],
  },
  {
    header: "Pharmaceutical waste treatment",
    point: [
      "Helps remove pharmaceutical residues and endocrine disrupting compounds from wastewater and drinking water sources.",
    ],
  },
  {
    header: "Cooling tower water treatment",
    point: [
      "Used to remove organic matter that can act as food for microbes and also adsorb minerals that contribute to scale formation.",
    ],
  },
  {
    header: "Metal plating waste treatment",
    point: [
      "Effective at removing heavy metals like cadmium, zinc, chromium, nickel and copper from electroplating wastewaters.",
    ],
  },
  {
    header: "Sugar decolorization",
    point: [
      "Used in sugar refining to adsorb color bodies and impurities from sugar juices, syrups and molasses.",
    ],
  },
  {
    header: "Natural organic matter removal",
    point: [
      "Removes dissolved organic matter that reacts with chlorine to form disinfection byproducts in drinking water.",
    ],
  },
  {
    header: "Cyanide removal",
    point: [
      "Cyanide forms strong complexes with carbon surface and is adsorbed from gold mine wastewater.",
    ],
  },
  {
    header: "Textile dye removal",
    point: [
      "Used to remove dye colors and residuals from textile mill effluents.",
    ],
  },
  {
    header: "Bilge water purification",
    point: [
      "Adsorbs oil and eliminates odors from bilge water accumulated in ships before discharge.",
    ],
  },
  {
    header: "Wine and juice processing",
    point: [
      "Removes organic contaminants and color from wines and fruit juices.",
    ],
  },
  {
    header: "Perchlorate removal",
    point: [
      "Effective at adsorbing perchlorate ions from drinking water and industrial wastewaters. Perchlorate is an emerging water contaminant.",
    ],
  },
  {
    header: "Arsenic removal",
    point: [
      "Used to adsorb arsenic from drinking water, especially relevant in areas with high natural arsenic levels.",
    ],
  },
  {
    header: "Phenol removal",
    point: [
      "Important application in treating wastewater from oil refineries, petrochemical plants, and other sources containing high phenol content.",
    ],
  },
  {
    header: "Humic acid removal",
    point: [
      "Adsorbs humic substances formed due to breakdown of organic matter in natural waters. Humic acids react with chlorine to form disinfection byproducts.",
    ],
  },
  {
    header: "Ozone/pesticide removal",
    point: [
      "Effective adsorbent in removing ozone and pesticide residuals sometimes found in bottled drinking water.",
    ],
  },
  {
    header: "Wastewater treatment",
    point: [
      "The high surface area and pore structure make it useful for adsorbing organic matter, colors, oils and other impurities from industrial wastewater streams. It can remove COD and BOD from effluents.",
    ],
  },
  {
    header: "Aquarium water filtration",
    point: [
      "In aquarium filters, coconut shell carbon removes dissolved organic compounds, colors, odors and medications from aquarium water.",
    ],
  },
  {
    header: "Air purification",
    point: [
      "The granular form can be used as a medium in air filters to adsorb gaseous pollutants and odors emitted from industrial processes.",
    ],
  },
  {
    header: "Mercury removal",
    point: [
      "Effective for adsorbing elemental mercury and mercury compounds from wastewater streams such as in chlor-alkali plants.",
    ],
  },
  {
    header: "Ballast water treatment",
    point: [
      "Used to remove invasive microorganisms from ballast water in ships to prevent transfer between ecosystems.",
    ],
  },
  {
    header: "Filter media",
    point: [
      "Used as a polishing filter to remove residual organic matter, color, and odors after biological treatment of wastewater.",
    ],
  },
  {
    header: "Sludge treatment",
    point: [
      "Added to sludge for adsorption of toxins and reducing the sludge volume.",
    ],
  },
  {
    header: "Septic system filtration",
    point: [
      "Removes contaminants from effluent of residential septic systems before discharge.",
    ],
  },
  {
    header: "Radioactive waste treatment",
    point: [
      "Selective for adsorbing radioactive iodine isotopes from nuclear facility wastewater.",
    ],
  },
  {
    header: "Oil spill remediation",
    point: [
      "Applied to adsorb oil spills in marine and freshwater environments as a cleanup measure.",
    ],
  },
  {
    header: "Fluoride removal",
    point: [
      "Coconut shell carbon can selectively remove excess fluoride from drinking water for defluoridation.",
    ],
  },
  {
    header: "Fish farm wastewater",
    point: [
      "Effective filter media for eliminating organic waste, residual feed and dyes from aquaculture effluents.",
    ],
  },
  {
    header: "Laundry waste treatment",
    point: [
      "Used in on-site laundry facilities to treat wash water containing detergents, bleaches and dyes.",
    ],
  },
  {
    header: "Nitrate removal",
    point: [
      "Can selectively remove nitrate contaminants from drinking water sources and agricultural runoff.",
    ],
  },
  {
    header: "Algal toxin removal",
    point: [
      "Used to remove algal toxins like microcystins produced during algal blooms in source water reservoirs and lakes.",
    ],
  },
  {
    header: "Siloxane removal",
    point: [
      "Applied in landfill gas applications to remove siloxanes which can damage equipment during energy recovery.",
    ],
  },
  {
    header: "Hydrogen sulfide removal",
    point: [
      "Adsorbs H2S gas from anaerobic wastewater and industrial processes to control odors and corrosion.",
    ],
  },
  {
    header: "Stormwater treatment",
    point: [
      "Used in stormwater filters and porous pavement systems to remove oils, grease, organic contaminants before discharge.",
    ],
  },
];

export const contentPointsOneForPageOne = [
  {
    header: "1. Evaluate Quality Specifications",
    point: [
      "When Exporting coconut shell activated carbon, We review the quality specifications like iodine number, mesh size, ash content,CTC etc. to ensure it meets your application requirements.",
    ],
  },
  {
    header: "2. We Provide Consistent Quality",
    point: [
      "Choose us for providing consistent and reliable activated carbon that complies with relevant quality standards.",
    ],
  },
  {
    header: "3. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "4. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "5. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "6. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "7. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "8. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "9. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "10. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "11. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
  {
    header: "12. Review Our Documentation for Product Integrity",
    point: [
      "Thoroughly assess documents like certificates of analysis to guarantee the integrity of the manufacturing process and end product.",
    ],
  },
];
