import React from "react";
import "./App.css";
import NavBar from "./component/navbar/navbar";
import MainHeader from "./component/main-header/main-header";
import {
  heroImageCarbon,
  aboutImageOne,
  aboutImageTwo,
  aboutImageThree,
  WhyUsImage,
} from "./site-data";
import Text from "./component/text/text";
import ContactPage from "./component/contact-page/contact-page";
import Footer from "./component/footer/footer";
import DetailsComponent from "./component/detail-component/detail-component";
import PageComponent from "./component/page-component/page-component";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  aboutText,
  aboutHeader,
  contentImgOne,
  contentTextOne,
  contentHeaderOne,
  contentSubHeaderOne,
  contentPointsOne,
  // contentImgTwo,
  contentTextTwo,
  contentHeaderTwo,
  contentPointsTwo,
  contentWaterPhaseImg,
  contentHeaderWaterPhase,
  contentTextWaterPhase,
  contentSubHeaderWaterPhase,
  contentPointsWaterPhase,
  contentAirPageImg,
  contentHeaderAirPage,
  contentSubHeaderAirPage,
  contentTextAirPage,
  contentPointsAirPage,
  contentGRPageImage,
  contentHeaderGRPage,
  contentSubHeaderGRPage,
  contentTextGRPage,
  contentPointsGRPage,
  contentHeaderWhyPage,
  contentTextWhyPage,
  contentSubHeaderWhyPage,
  contentPointsWhyPage,
  contentHeaderCarbonPage,
  contentSubHeaderCarbonPage,
  contentTextCarbonPage,
  contentPointsCarbonPage,
  collageImageArray,
} from "./site-data";
import PrivacyPolicy from "./component/privacy-policy/privacy-policy";

class App extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Routes>
            {/* Page navigation */}
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/why-we-are-better-than-others"
              element={
                <PageComponent
                  pageHeader="why-we-are-better-than-others"
                  image={WhyUsImage}
                  header={contentHeaderWhyPage}
                  headerContent={contentTextWhyPage}
                  subHeader={contentSubHeaderWhyPage}
                  points={contentPointsWhyPage}
                />
              }
            />
            <Route
              path="/carbon-use"
              element={
                <PageComponent
                  pageHeader="carbon-use"
                  // image={CarbonUse}
                  imageArray={collageImageArray}
                  header={contentHeaderCarbonPage}
                  headerContent={contentTextCarbonPage}
                  subHeader={contentSubHeaderCarbonPage}
                  points={contentPointsCarbonPage}
                />
              }
            />
            <Route
              path="/water-phase"
              element={
                <PageComponent
                  pageHeader="water-phase"
                  image={contentWaterPhaseImg}
                  header={contentHeaderWaterPhase}
                  headerContent={contentTextWaterPhase}
                  subHeader={contentSubHeaderWaterPhase}
                  points={contentPointsWaterPhase}
                />
              }
            />
            <Route
              path="/air-phase"
              element={
                <PageComponent
                  pageHeader="air-phase"
                  image={contentAirPageImg}
                  header={contentHeaderAirPage}
                  headerContent={contentTextAirPage}
                  subHeader={contentSubHeaderAirPage}
                  points={contentPointsAirPage}
                />
              }
            />
            <Route
              path="/gold-recovery"
              element={
                <PageComponent
                  pageHeader="gold-recovery"
                  image={contentGRPageImage}
                  header={contentHeaderGRPage}
                  headerContent={contentTextGRPage}
                  subHeader={contentSubHeaderGRPage}
                  points={contentPointsGRPage}
                />
              }
            />
          </Routes>
        </Router>
      </div>
    );
  }
}

class Home extends React.Component {
  render() {
    return (
      <>
        <div className="sticky top-0 bg-white z-50 shadow-sm">
          <NavBar />
        </div>
        {/* Home Page */}
        <div className="w-screen hero-container flex flex-wrap justify-center items-center relative overflow-hidden px-10">
          <img
            src={heroImageCarbon}
            className="w-full h-full object-cover absolute top-0 left-0"
            alt="hero_img"
          />
          <div className=" p-10 backdrop-blur rounded-[30px] hero-content-container">
            <p className="text-white text-[32px] text-center italic">
              Welcome to
            </p>
            <h1 className="text-white text-[92px] text-center">Carbonix</h1>
            <p className="text-white text-[32px] text-center">
              The forefront of Industrial purification
            </p>
          </div>
        </div>

        {/* About Page */}
        <div className="p-20 flex items-center gap-10 about-section">
          <div className="w-full flex flex-col gap-0">
            <Link to={`/gold-recovery`}>
              <div className="about-one overflow-hidden ">
                <img
                  className="w-full rounded-t-md about-image-one transition-all"
                  src={aboutImageOne}
                  alt=""
                />
              </div>
            </Link>
            <Link to={`/air-phase`}>
              <div className="about-two overflow-hidden">
                <img className="w-full" src={aboutImageTwo} alt="" />
              </div>
            </Link>
            <Link to={`/water-phase`}>
              <div className="about-three overflow-hidden">
                <img
                  className="w-full rounded-b-md"
                  src={aboutImageThree}
                  alt=""
                />
              </div>
            </Link>
          </div>
          <section className="flex flex-col gap-5 w-full about-content">
            <MainHeader text={aboutHeader} />
            <Text text={aboutText} />
          </section>
        </div>

        {/* Why we are better than others page */}
        <div className="p-20 flex flex-col items-center gap-40 why-us-container">
          <DetailsComponent
            pageHeader="why-we-are-better-than-others"
            image={contentImgOne}
            header={contentHeaderOne}
            contentText={contentTextOne}
            subHeader={contentSubHeaderOne}
            points={contentPointsOne}
            buttonName={"View all reasons"}
          />

          {/* Where are all activated carbon can be used */}
          <DetailsComponent
            pageHeader="carbon-use"
            imageCollage={true}
            // image={contentImgTwo}
            imageArray={collageImageArray}
            header={contentHeaderTwo}
            contentText={contentTextTwo}
            points={contentPointsTwo}
            buttonName={"View all uses"}
          />
        </div>

        {/* Contact Page */}
        <ContactPage />

        {/* Footer */}
        <Footer />
      </>
    );
  }
}
export default App;
