import React from "react";

class Text extends React.Component {
  render() {
    const { text } = this.props;
    const textStyle = {
      color: "#787878",
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      textAlign: "left",
    };
    return (
      <>
        <p style={textStyle}>{text}</p>
      </>
    );
  }
}

export default Text;
