import React from "react";
import emailjs from "emailjs-com";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      userEmail: "",
      message: "",
      isSending: false,
    };
  }

  // Toast notification

  showSuccessToast = () => {
    this.setState({ showToast: true, toastType: "success" });
    setTimeout(() => {
      this.setState({ showToast: false });
    }, 3000);
  };

  showErrorToast = () => {
    this.setState({ showToast: true, toastType: "error" });
    setTimeout(() => {
      this.setState({ showToast: false });
    }, 3000);
  };

  // Input validations

  handleInputChange = (event, field) => {
    event.preventDefault();
    this.setState({ [field]: event.target.value });
  };

  isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  isFormValid = () => {
    const { name, userEmail, message } = this.state;
    return name && this.isEmailValid(userEmail) && message;
  };

  // reset form

  resetForm = () => {
    this.setState({
      name: "",
      userEmail: "",
      message: "",
    });
  };

  // Email Service

  sendEmail = (e) => {
    e.preventDefault();
    const { userEmail, name, message } = this.state;
    const templateId = "template_7xzxigm";
    const userId = "IBfCvxdfMu-TMF9-o";
    const serviceId = "service_g2e4hfh";
    this.setState({ isSending: true });
    console.log(serviceId)
    emailjs
      .send(
        serviceId,
        templateId, 
        { name: name, message: message, userEmail: userEmail },
        userId
      )
      .then(
        (response) => {
          console.log(response)
          this.showSuccessToast();
        },
        (error) => {
          console.log(error)
          this.showErrorToast();
        }
      )
      .finally(() => {
        this.resetForm();
        this.setState({ isSending: false }); // Reset loading state
      });
  };

  render() {
    const { name, userEmail, message } = this.state;
    const { showToast, toastType, isSending } = this.state;

    const buttonStyle = {
      color: "#FFFFFF",
      display: "flex",
      padding: "8px 22px",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      borderRadius: "5px",
      background: "#AA5647",
      width: "75%",
    };

    const inputStyle = {
      border: "none",
      borderRadius: "8px",
      padding: "16px 12px",
      background: "#FFF",
      fontSize: "16px",
      width: "100%",
    };

    return (
      <>
        <div className={`toast ${showToast ? "show" : ""} ${toastType}`}>
          {toastType === "success"
            ? "Email sent successfully!"
            : "Failed to send email. Please try again."}
        </div>

        <div className="p-20 flex justify-center items-center w-full relative">
          {isSending && (
            <div className="loading-container">
              <div className="loading-circle"></div>
            </div>
          )}
          <form className="flex flex-col justify-center items-center gap-6 w-full p-[64px] bg-[#F8F8F8] contact-form">
            <p className="text-2xl font-bold">Contact Us</p>

            <div className="flex flex-col gap-4 w-3/4">
              <input
                className="form-field"
                value={name}
                onChange={(e) => this.handleInputChange(e, "name")}
                onBlur={() => this.setState({ nameTouched: true })}
                style={inputStyle}
                type="text"
                placeholder="Your name"
              />

              <input
                className="form-field"
                value={userEmail}
                onChange={(e) => this.handleInputChange(e, "userEmail")}
                onBlur={() => this.setState({ emailTouched: true })}
                style={inputStyle}
                type="email"
                placeholder="Your email"
              />

              <textarea
                className="min-h-[151px] form-field"
                value={message}
                onChange={(e) => this.handleInputChange(e, "message")}
                onBlur={() => this.setState({ messageTouched: true })}
                style={inputStyle}
                placeholder="Your message"
              ></textarea>
            </div>
            <button
              className={
                this.isFormValid()
                  ? "transition-all"
                  : "opacity-50 cursor-not-allowed"
              }
              style={buttonStyle}
              onClick={this.sendEmail}
              disabled={!this.isFormValid()}
            >
              Send &#x2192;
            </button>
          </form>
        </div>
      </>
    );
  }
}

export default ContactPage;
